/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto-v20-latin-regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('assets/fonts/roboto-v20-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'),
    url('assets/fonts/roboto-v20-latin-regular.woff') format('woff'),
    url('assets/fonts/roboto-v20-latin-regular.ttf') format('truetype'),
    url('assets/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg');
}

.login-page .card-login.card-white .form-control{
  height: inherit;
  padding-left: 10px;
  font-size: 13px;
}

body {
  font-family: 'roboto' !important;
}

.tableLink {
  color: #111130;
}
.tableLink:hover {
  color: #1e88f7;
}

.sep {
  margin: 25px 0;
  background: rgba(119, 119, 119, 0.3);
  width: 100%;
  height: 1px;
}

.artistButlerName {
  font-family: 'adam';
  font-size: 2.7em;
}

.membershipVersion {
  font-weight: bold;
  color: #111130;
}

.priceBox {
  font-size: 2em;
  border: 1px solid #111130;
  border-radius: 0.5em;
  padding: 0.5em 1em;
}
.price {
  font-size: 2em;
  margin: 0 10px;
}

.moms {
  font-size: 0.5em;
}
.card form label + .form-control {
  margin-bottom: 0 !important;
}

.logo {
  padding: 0.5em 0.7em 0.5rem 2em !important;
}

.login-page .card-login .card-header {
  padding: 0 0 0 !important;
}

/* .login-page .card-login .card-header .card-title {
  text-transform: unset !important;
  margin-left: 10px !important;
  margin-bottom: 100px !important;
  font-size: 2.5em !important;
} */

.table-responsive {
  overflow-x: auto !important;
  overflow-y: unset !important;
}

.btn.dropdown-toggle[data-toggle='dropdown'] {
  padding: 11px 40px !important;
  margin: 4px 1px 4px 4px !important;
}

.card-header {
  background-color: unset !important;
  border-bottom: unset !important;
}

.main-panel > .content {
  padding: 50px 30px 30px 280px !important;
}

.sidebar::before {
  display: none;
}

@media (max-width: 767.98px) {
  .main-panel .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* Progess circles at the button at login */
.circleFill {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 12.5px;
  color: #fff;
  line-height: 26px;
  text-align: center;
  background: #111130;
}

.circleEmpty {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #111130;
  font-size: 12.5px;
  color: black;
  line-height: 22px;
  text-align: center;
  background: white;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.statusCircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0px;
  border-color: rgba(0, 0, 0, 0.1);
  font-size: 12.5px;
  color: black;
  line-height: 22px;
  text-align: center;
}

/* To make the card title with non-capitalized */
.card-category {
  text-transform: initial !important;
  /* font-size: 1px !important; */
}

.main-panel > .content {
  padding-bottom: 0px !important;
}

/* Sidebar */
.sidebar .nav li.active > a:not([data-toggle='collapse']) {
  background-color: #888898 !important;
  border-radius: 0%;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 23px;
  display: block;
}

.sidebar .nav li.active > a:not([data-toggle='collapse'])::before,
.off-canvas-sidebar .nav li.active > a:not([data-toggle='collapse'])::before {
  display: none !important;
}

/* Mobile view/Sidebar collapsed */
@media screen and (max-width: 600px) {
  .sidebar {
    margin-top: 0px;
  }
  .form-group .form-control {
    font-size: 16px;
  }
  .LabelReplacerLabel {
    font-size: 16px;
  }
  .rt-thead.-filters input {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1200px) {
  .sidebar {
    margin-top: 50px !important;
  }
}
