.contactsTable {
  min-height: calc(100vh - 282px) !important;
  width: 100% !important;
  border: 0px !important;
}
.rt-thead,
.rt-tbody {
  min-width: 100% !important;
}

.ReactTable {
  border: 0px !important;
}
.contactsTable .rt-thead.-filters input,
.contactsTable .rt-thead.-filters select,
.contactsTable .rt-thead.-filters,
.contactsTable .rt-thead.-filters .rt-th {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  background: #fff;
  padding: 0px 0px !important;
  font-size: inherit !important;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
  margin-left: 0px !important;
}
.contactsTable .rt-thead.-filters input {
  margin-left: 5px;
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
}
.contactsTable .rt-thead.-filters select {
  margin-left: 0px;
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
}
.contactsTable .rt-tbody .rt-td {
  margin-left: -5px;
}
.contactsTable .rt-thead .rt-th,
.contactsTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 0px solid rgba(0, 0, 0, 0.05) !important;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent !important;
}
/* https://github.com/tannerlinsley/react-table/issues/444 */

.contactsTable .rt-resizable-header-content:after {
  position: sticky;
  left: 0px;
}
.contactsTable .rt-resizable-header-content:before {
  position: sticky;
  left: 6px;
}
/* Removes sorting border */
.contactsTable .rt-thead .rt-th.-sort-desc,
.contactsTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6);
}

.contactsTable .rt-thead .rt-th.-sort-asc,
.contactsTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6);
}

/* Removes the option to resize */
.contactsTable .rt-resizer {
  display: none;
}
.contactsTable .rt-thead .rt-resizable-header-content {
  margin-left: 15px;
}

.contactsTable .rt-thead .rt-resizable-header-content {
  font-weight: lighter;
}

/* -- Styled Selects - wrapped due to FF appearance bug & MSIE -- */
select {
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
}

.myPanelBox::after {
  position: absolute;
  left: 67px !important;
  bottom: 0;
  content: '▼';
  float: right;
  color: #888898;
  z-index: 1 !important;
  pointer-events: none;
}
.myPanelBox:hover::after {
  color: #111130;
  content: '▼';
}

select option:hover {
  box-shadow: 0 0 100px 100px red inset !important;
}

.organizerNameInput {
  margin-left: 0px !important;
}

.rt-tr-group {
  min-height: 60px;
  max-height: 60px;
}

.contactsTable .rt-tbody .rt-tr-group:last-child {
  border-bottom-color: rgba(34, 42, 66, 0.2);
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.contactsTable .rt-tbody .rt-tr-group {
  border-bottom-color: rgba(34, 42, 66, 0.2);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
@media screen and (max-width: 600px) {
  /* Removes arrangør */
  .contactsTable .rt-td:nth-child(3) {
    display: none !important;
  }

  .contactsTable .rt-thead .rt-th:nth-child(3) {
    display: none !important;
  }

  /* Removes pris */
  .contactsTable .rt-td:nth-child(4) {
    display: none !important;
  }

  .contactsTable .rt-thead .rt-th:nth-child(4) {
    display: none !important;
  }

  .contactButtonDiv {
    margin-bottom: -25px;
    display: flex;
    justify-content: center;
  }
}
