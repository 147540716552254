.ContactCardHeader{
  padding-left: 45px !important;
}

@media screen and (max-width: 991px) {
  .ContactCardHeader{
    text-align: center;
  }
  .ContactCardBody{
    padding-left: 30px !important;
    padding-right: 30px !important; 
  }
}