.inputGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-bottom: 10px;
  .base {
    color: #222a42;
    border-color: rgba(29, 37, 59, 0.2);
    padding: 10px 18px 10px 18px;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    box-shadow: none;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    line-height: 1.42857;
    height: calc(2.25rem + 2px);
  }
  .focus {
    border-color: #1d8cf8;
  }
  .complete {
    border-color: green;
  }
  .invalid {
    border-color: #ec250d;
  }
}


.infoText{
  font-size: 12px;
  text-align: center;
  margin-bottom: 20px;
  color: 222a42;
}
