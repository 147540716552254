.list {
  list-style: none;
  padding: 0;
  position: absolute;
  background: #fff;
  z-index: 1;
  width: 100%;
  box-shadow: 0.2em 0.2em 0.8em 0em rgba(0, 0, 0, 0.75);
  border-radius: 0.3em;
  .listItem {
    padding: 1em 1.5em;
    &:hover {
      background: #111130;
      color: #fff;
      cursor: pointer;
      border-radius: 0.3em;
    }
  }
}
