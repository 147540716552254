header {
  border-bottom: 2px solid #333;
  height: 100%;
  padding: 25px 0;
  margin-bottom: 25px;
  h1 {
    margin-bottom: 15px;
  }

  .lead {
    font-size: 1.3em;
  }
}
