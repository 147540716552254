.wrapper {
  font-size: 1.5em;
}

.canceled {
  color: red;
}

.next {
  margin-bottom: 2em;
}

.paymentsWrapper {
  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  hr {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
  }
  .payments {
    padding: 0.4em 0;
    display: flex;
    justify-content: space-between;
  }
}
