/* Makes the dialog box in the center of the screen */
.modal-dialog {
  position: absolute;
  top: 50%;
  transform: translate(-0%, -50%) !important;
}

.modal-body{
    padding: 24px 24px 0px 24px !important;
}

.LabelReplacerLabel {
  font-weight: lighter;
  /* font-size: 16px; */
}

.card label {
  /* display: none; */
}

.has-success::after,
.has-danger::after {
  display: none !important;
}