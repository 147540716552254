.hover {
  &:hover {
    cursor: pointer;
  }
}
.CardHeader {
  font-size: 25px;
  text-align: left;
  margin-bottom: 25px;
  margin-left: 0px;
  color: #222a42;
}

@media (max-width: 767.98px) {
  .CardHeader {
    padding: 0 15px;
  }
}
