.OuterCard {
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .OuterCard {
    width: 100%;
    left: 0%;
  }
}

.ContactCard {
  width: 80% !important;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .ContactCard {
    width: 100% !important;
    left: 0%;
  }
}
@media screen and (max-width: 1200px) {
  .ProfileCard {
    width: 100% !important;
    left: 0%;
  }
}

.ContactCardBody {
  padding-left: 45px !important;
}
@media screen and (max-width: 991px) {
  .ContactCardBody {
    padding-left: 15px !important;
  }
}

#note {
  height: 100%;
}

.has-success.form-group .form-control {
  padding-right: 0px !important;
  padding-left: 8px;
}

.form-group .form-control {
  padding-right: 0px;
  padding-left: 8px;
}
.OrganizerBody {
  width: 50%;
}

.card form label + .form-control,
.white-content .form-control,
.form-group .form-control {
  height: calc(2.25rem - 5px);
  padding-left: 10px !important;
  padding-right: 0px !important;
}

.has-success::after,
.has-danger::after {
  display: none;
}

.LabelReplacerLabel {
  font-weight: lighter;
  /* font-size: 16px; */
}

.LabelReplacerCol {
  padding-top: 5px;
  padding-right: 0px;
}

textarea.form-control {
  max-height: 195px;
}

/* ========== Cards ========== */
.card label {
  /* display: none; */
}

.CardOrganizer {
  padding-left: 20px;
}

.CardVenue {
  padding-right: 20px;
}

.CardEvent {
  /* style={{ paddingLeft: '20px' }} */
  padding-left: 20px;
  padding-right: 20px;
}

.CardContact {
  padding-left: 20px;
  padding-right: 20px;
}

.CardTime {
  padding-left: 20px;
  padding-right: 20px;
}

.CardExpenses {
  padding-left: 20px;
  padding-right: 20px;
}

.ColEventDetails {
  padding-left: 30px;
}

.ColEvent {
  padding-right: 30px;
}
/* ========== Cards ========== */

/* ========== Dropdown ========== */
/* .dropup .dropdown-menu::after {
  color: #111130;
}

.dropdown-menu {
  background-color: #111130;
  border-radius: 0.4285rem;
}

.dropdown-menu .dropdown-item {
  color: white;
} */
/* ========== Dropdown ========== */

/* ========== DatePicker ========== */
/* .rdt.rdtOpen .rdtPicker {
  background-color: #111130;
  border-radius: 0.4285rem;
}

.rdtDays tr .rdtDay {
  color: white;
}

.rdtDay.rdtActive,
.rdtDay.rdtActive:hover {
  background-color: #888898 !important;
  color: white;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker .rdtTimeToggle:hover::before {
  background-color: #888898;
  color: white;
}
.rdtPicker .rdtTimeToggle {
  color: white;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker .rdtTimeToggle:hover::before {
  background-color: #888898;
  color: white;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: white;
}

.rdtPicker thead tr:first-child th:hover {
  background-color: #888898;
  color: white;
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: white;
  border-radius: 50%;
  border: 1px solid white;
}

.rdtCounter .rdtCount {
  color: white;
  border-radius: 50%;
  border: 1px solid white;
}

.rdtCounter .rdtBtn:hover {
  background-color: #888898;
  color: white;
} */

/* Removes the original arrow */
/* .rdtPicker::after {
  display: none;
} */
/* Adds own arrow */
/* .rdtPicker::before {
  content: '▲';
  color: #111130;
  position: absolute;
  top: -11px;
  left: 3px;
  border-bottom: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  display: inline-block;
} */
/* ========== DatePicker ========== */

@media screen and (max-width: 990px) {
  .CardOrganizer,
  .CardVenue,
  .CardEvent,
  .CardContact,
  .CardTime,
  .CardExpenses,
  .ColEventDetails,
  .ColEvent {
    padding-left: 20px;
    padding-right: 20px;
  }
  .eventDetaljerTitle {
    padding-top: 25px;
  }
}

.rt-resizer {
  display: none;
}
