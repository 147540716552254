 {
}
 {
}

@media screen and (max-width: 1200px) {
  .card-header {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .ChangePasswordCardBody,
  .ProfileInfoCardBody {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
